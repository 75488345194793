import { defineStore } from "pinia";
import type { AnybillResult } from "~/additionalModels/AnybillResponse";
import AccessGroupCustomerEnum from "~/models/AccessGroupCustomerEnum";
import type CreatePortalUserGroupDto from "~/models/CreatePortalUserGroupDto";
import type PortalUserGroupDetailsDto from "~/models/PortalUserGroupDetailsDto";
import type PortalUserGroupDto from "~/models/PortalUserGroupDto";
import type PortalUserGroupUserDto from "~/models/PortalUserGroupUserDto";
import UpdatePortalUserGroupAssignment from "~/models/UpdatePortalUserGroupAssignment";
import type UpdatePortalUserGroupDto from "~/models/UpdatePortalUserGroupDto";

export const usePermissionsModule = defineStore("permissions", () => {
  const userModule = useUserModule();
  const { userRights } = storeToRefs(userModule);

  const userPermissions = ref<AccessGroupCustomerEnum[] | null>(null);
  const permissionGroupDetails = ref<PortalUserGroupDetailsDto[] | null>(null);
  const loading = ref(false);
  const initialized = computed(() => userPermissions.value !== null);

  /* INTERNAL FUNCTIONS */
  function _setLoading(isLoading: boolean) {
    loading.value = isLoading;
  }

  function _setPermissions(permissions: AccessGroupCustomerEnum[] | null) {
    userPermissions.value = permissions;
  }

  function _setPermissionGroupsDetails(pGroup: PortalUserGroupDetailsDto[] | null) {
    permissionGroupDetails.value = pGroup;
  }

  function _pushPermissionGroupDetails(group: PortalUserGroupDetailsDto) {
    permissionGroupDetails.value!.push(group);
  }

  function _updatePermissionGroupDetails(group: { id: string }) {
    const changeIndx = permissionGroupDetails.value!.findIndex((g: { id: any }) => g.id === group.id);
    Object.assign(permissionGroupDetails.value![changeIndx], group);
  }

  function _removePermissionGroupDetails(group: { id: string }) {
    permissionGroupDetails.value = permissionGroupDetails.value!
      .filter(
        g => g.id !== group.id,
      // (g: { id: any }) => g.id !== group.id
      );
  }

  function _updateAssignments(groupId: string, assignments: PortalUserGroupUserDto[] | null) {
    const clone = useCloneDeep(permissionGroupDetails.value);
    const toChangeIndx = clone!.findIndex(group => group.id === groupId);
    clone![toChangeIndx].assignments = assignments;
    permissionGroupDetails.value = clone;
  }

  /* PUBLIC */
  function reset() {
    _setPermissions(null);
    _setPermissionGroupsDetails(null);
  }

  async function populate(): Promise<void> {
    _setLoading(true);
    const accessGroupRes = await useTypedFetch<AnybillResult<AccessGroupCustomerEnum[]>>("/permissionService/get");
    if (accessGroupRes.success) {
      _setPermissions(accessGroupRes.value);
    }
    else {
      await AnybillLogger.instance.error("PermissionModule populating failed for access group enums", new Error(accessGroupRes.errorMessage ?? "no details"));
      return;
    }
    let groups: PortalUserGroupDto[] = [];
    if (userRights.value?.get(AccessGroupCustomerEnum.PortalUser) ?? 0 >= 1) {
      const response = await useTypedFetch<AnybillResult<PortalUserGroupDto[]>>("/permissionGroupService/get");

      if (response.success) { groups = response.value; }
      else {
        await AnybillLogger.instance.error("PermissionModule populate - permissionGroupService", new Error(response.errorMessage ?? "no details"));
        return;
      }
    }

    const groupDetails: PortalUserGroupDetailsDto[] = [];
    for (const group of groups) {
      const response = await useTypedFetch<AnybillResult<PortalUserGroupDetailsDto>>(`/permissionGroupSpecificService/get`, {
        id: group.id,
      });
      if (response.success) { groupDetails.push(response.value); }
      else {
        await AnybillLogger.instance.error("PermissionModule populate - permissionGroupSpecificService", new Error(response.errorMessage ?? "no details"));
        return;
      }
    }
    _setPermissionGroupsDetails(groupDetails);
    _setLoading(false);
  }

  /**
   * Add a permission group
   *
   * @async
   * @param {*} groupDetails
   * @returns {Promise<void>}
   */
  async function addPermissionGroup(groupDetails: CreatePortalUserGroupDto): Promise<void> {
    const response = await useTypedFetch<AnybillResult<PortalUserGroupDetailsDto>>("/permissionGroupService/post", {
      groupDetails,
    });
    if (response.success)
      _pushPermissionGroupDetails(response.value);
    else
      await AnybillLogger.instance.error("PermissionModule populate - addPermissionGroup", new Error(response.errorMessage ?? "no details"));
  }

  /**
   * Remove a permission group
   *
   * @async
   * @param {string} groupDetailId The ID for the permission group that will be deleted
   */
  async function deletePermissionGroup(groupDetailId: string): Promise<void> {
    const response = await useTypedFetch<AnybillResult<PortalUserGroupDetailsDto>>(`/permissionGroupSpecificService/delete`, {
      id: groupDetailId,
    });

    if (response.success)
      _removePermissionGroupDetails({ id: groupDetailId });
    else
      await AnybillLogger.instance.error("PermissionModule populate - deletePermissionGroup", new Error(response.errorMessage ?? "no details"));
  }

  async function updateUserGroup(groupDetails: UpdatePortalUserGroupDto, id: string): Promise<void> {
  /* update group data */
    const responseGroup = await useTypedFetch<AnybillResult<PortalUserGroupDetailsDto>>(`/permissionGroupService/put`, {
      id,
      group: groupDetails,
    });
    if (responseGroup.success)
      _updatePermissionGroupDetails(responseGroup.value);
    else
      await AnybillLogger.instance.error("PermissionModule populate - updatePermissionGroup", new Error(responseGroup.errorMessage ?? "no details"));
  }

  async function updatePermissionGroup(groupDetails: PortalUserGroupDetailsDto): Promise<void> {
    /* update group permissions */
    const responsePermissions = await useTypedFetch<AnybillResult<PortalUserGroupDetailsDto>>(`/permissionGroupSpecificService/put`, {
      id: groupDetails.id,
      permissions: groupDetails.permissions,
    });

    if (responsePermissions.success)
      _updatePermissionGroupDetails(responsePermissions.value);
    else
      await AnybillLogger.instance.error("PermissionModule populate - updatePermissionGroup", new Error(responsePermissions.errorMessage ?? "no details"));
  }

  async function addAssignment(assignment: { groupId: string; toAdd: any }): Promise<void> {
    const changeIndx = permissionGroupDetails.value!.findIndex((group: { id: any }) => group.id === assignment.groupId);
    const newAssignments = [...(permissionGroupDetails.value![changeIndx].assignments || []).map((a: { id: any }) => a.id), assignment.toAdd];
    const updateAssignmentDto = new UpdatePortalUserGroupAssignment({ userIds: newAssignments.map(a => a) });
    const response = await useTypedFetch<AnybillResult<PortalUserGroupDetailsDto>>(`/permissionGroupSpecificAssignmentService/put`, {
      id: assignment.groupId,
      assignment: updateAssignmentDto,
    });
    if (response.success)
      _updateAssignments(response.value.id, response.value!.assignments);
    else
      await AnybillLogger.instance.error("PermissionModule populate - addAssignment", new Error(response.errorMessage ?? "no details"));
  }

  async function removeAssignment(assignment: { groupId: string; toRemove: any }): Promise<void> {
    const changeIndx = permissionGroupDetails.value!.findIndex((group: { id: any }) => group.id === assignment.groupId);
    const newAssignments = (permissionGroupDetails.value![changeIndx].assignments || []).filter((a: { id: any }) => a.id !== assignment.toRemove);
    const updateAssignmentDto = new UpdatePortalUserGroupAssignment({ userIds: newAssignments.map((a: { id: any }) => a.id) });
    const response = await useTypedFetch<AnybillResult<PortalUserGroupDetailsDto>>(`/permissionGroupSpecificAssignmentService/put`, {
      id: assignment.groupId,
      body: updateAssignmentDto,
    });
    if (response.success)
      _updateAssignments(response.value.id, response.value!.assignments);
    else

      await AnybillLogger.instance.error("PermissionModule populate - removeAssignment", new Error(response.errorMessage ?? "no details"));
  }

  return { initialized, loading, reset, userPermissions, permissionGroupDetails, populate, addPermissionGroup, deletePermissionGroup, updatePermissionGroup, updateUserGroup, addAssignment, removeAssignment };
});
