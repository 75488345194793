import jsonify_LXSbrlp2oZ from "/app/anybill-partner-portal-v3/plugins/jsonify.ts";
import revive_payload_client_4sVQNw7RlN from "/app/anybill-partner-portal-v3/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/anybill-partner-portal-v3/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/anybill-partner-portal-v3/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/app/anybill-partner-portal-v3/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/app/anybill-partner-portal-v3/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/anybill-partner-portal-v3/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/app/anybill-partner-portal-v3/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/app/anybill-partner-portal-v3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/anybill-partner-portal-v3/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/anybill-partner-portal-v3/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tbFNToZNim from "/app/anybill-partner-portal-v3/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import i18n_yfWm7jX06p from "/app/anybill-partner-portal-v3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_1UohGbtF8v from "/app/anybill-partner-portal-v3/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import logger_kEXgbufg9p from "/app/anybill-partner-portal-v3/plugins/logger.ts";
import error_handler_kEP5FliEXj from "/app/anybill-partner-portal-v3/plugins/error-handler.ts";
import vuetify_7h9QAQEssH from "/app/anybill-partner-portal-v3/plugins/vuetify.ts";
export default [
  jsonify_LXSbrlp2oZ,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_tbFNToZNim,
  i18n_yfWm7jX06p,
  plugin_1UohGbtF8v,
  logger_kEXgbufg9p,
  error_handler_kEP5FliEXj,
  vuetify_7h9QAQEssH
]