import type { AnybillResult } from "~/additionalModels/AnybillResponse";
import EndpointPaths from "~/config/constants/EndpointPaths";
import type CreateStoreDetailDto from "~/models/CreateStoreDetailDto";
import type CreateStoreDto from "~/models/CreateStoreDto";
import type CreateVendorApiUserDto from "~/models/CreateVendorApiUserDto";
import type CustomerModuleDto from "~/models/CustomerModuleDto";
import type InstalledModuleDto from "~/models/InstalledModuleDto";
import type StoreDto from "~/models/StoreDto";
import type UpdateVendorApiUserDto from "~/models/UpdateVendorApiUserDto";
import type UpdateVendorCustomerDto from "~/models/UpdateVendorCustomerDto";
import type VendorApiUserCredentialsDto from "~/models/VendorApiUserCredentialsDto";
import type VendorApiUserDto from "~/models/VendorApiUserDto";
import type VendorCustomerDto from "~/models/VendorCustomerDto";
import BaseService from "~/services/BaseService";

export class VendorCustomerProvider {
  private static _instance: VendorCustomerProvider;

  static get instance(): VendorCustomerProvider {
    if (VendorCustomerProvider._instance === undefined)
      VendorCustomerProvider._instance = new VendorCustomerProvider();

    return VendorCustomerProvider._instance;
  }

  /**
   * Get all stores of linked customer
   *
   * @public
   * @async
   * @param {?{
   *     skip: number;
   *     take: number;
   *     vendorCustomerId: string;
   *   }} [_params] skip/take/vendorCustomerId
   * @returns {Promise<AnybillResult<StoreDto[]>>} StoreDto list
   */
  public async getAllStores(_params?: {
    skip: number;
    take: number;
    vendorCustomerId: string;
  }): Promise<AnybillResult<StoreDto[]>> {
    return BaseService.Instance.get<StoreDto[]>(
      {
        path: `${EndpointPaths.VENDOR_CUSTOMER_PREPEND_PATH}${_params?.vendorCustomerId}${EndpointPaths.VENDOR_CUSTOMER_STORES_APPEND_PATH}`,
        query: _params,
      },
    );
  }

  /**
   * Get a single store
   *
   * @public
   * @async
   * @param {string} vendorCustomerId Id of the customer
   * @param {string} storeId Id of the Store
   * @param {?{
   *       resourceSize: string;
   *     }} [_params] Optional desired resource size of the store image. Available values : Original, Small, Medium, Large
   * @returns {Promise<AnybillResult<StoreDto>>} Store DTO of requested Id
   */
  public async getSingleStore(
    vendorCustomerId: string,
    storeId: string,
    _params?: {
      resourceSize: string;
    },
  ): Promise<AnybillResult<StoreDto>> {
    return BaseService.Instance.get<StoreDto>(
      {
        path: `${EndpointPaths.VENDOR_CUSTOMER_PREPEND_PATH}${vendorCustomerId}${EndpointPaths.VENDOR_CUSTOMER_STORES_APPEND_PATH}/${storeId}`,
        query: _params,
      },
    );
  }

  /**
   * Get modules
   *
   * @public
   * @async
   * @returns {Promise<AnybillResult<InstalledModuleDto[]>>} Store DTO of requested Id
   */
  public async getCustomerModules(): Promise<AnybillResult<InstalledModuleDto[]>> {
    return BaseService.Instance.get<InstalledModuleDto[]>(
      {
        path: `${EndpointPaths.CUSTOMER_MODULES_PATH}`,
      },
    );
  }

  /**
   * Set/Update a module
   *
   * @public
   * @async
   */
  public async updateCustomerModule(
    customerModule: CustomerModuleDto,
  ): Promise<AnybillResult<InstalledModuleDto>> {
    return BaseService.Instance.put<InstalledModuleDto>(
      {
        path: `${EndpointPaths.CUSTOMER_MODULES_PATH}`,
        body: customerModule,
      },
    );
  }

  /**
   * Get API users of customer
   *
   * @public
   * @async
   * @param {string} vendorCustomerId Id of the customer
   * @returns {Promise<AnybillResult<VendorApiUserDto>>} Store DTO of requested Id
   */
  public async getApiUsers(
    vendorCustomerId: string,
    _params?: {
      skip: number;
      take: number;
    },
  ): Promise<AnybillResult<VendorApiUserDto[]>> {
    return BaseService.Instance.get<VendorApiUserDto[]>(
      {
        path: `${EndpointPaths.VENDOR_CUSTOMER_PREPEND_PATH}${vendorCustomerId}${EndpointPaths.VENDOR_CUSTOMER_API_USERS_APPEND_PATH}`,
        query: _params,
      },
    );
  }

  /**
   * Create a store
   *
   * @public
   * @async
   */
  public async createStore(
    vendorCustomerId: string,
    createStoreDto: CreateStoreDto,
  ): Promise<AnybillResult<StoreDto>> {
    return BaseService.Instance.post<StoreDto>(
      {
        path: `${EndpointPaths.VENDOR_CUSTOMER_PREPEND_PATH}${vendorCustomerId}${EndpointPaths.VENDOR_CUSTOMER_STORES_APPEND_PATH}`,
        body: createStoreDto.toDto(),
      },
    );
  }

  /**
   * Upload a store icon
   *
   * @public
   * @async
   */
  public async uploadStoreIcon(
    vendorCustomerId: string,
    storeId: string,
    file: File,
  ): Promise<AnybillResult<undefined>> {
    const formData = new FormData();
    formData.append("file", file ?? "");

    return BaseService.Instance.post<undefined>(
      {
        path: `${EndpointPaths.VENDOR_CUSTOMER_PREPEND_PATH}${vendorCustomerId}${EndpointPaths.VENDOR_CUSTOMER_STORES_APPEND_PATH}/${storeId}${EndpointPaths.VENDOR_CUSTOMER_ICON_APPEND_PATH}`,
        body: formData,
      },
    );
  }

  /**
   * Add Vendor API User
   *
   * @public
   * @async
   */
  public async addApiUser(
    vendorCustomerId: string,
    createVendorApiUserDto: CreateVendorApiUserDto,
  ): Promise<AnybillResult<VendorApiUserCredentialsDto>> {
    return BaseService.Instance.post<VendorApiUserCredentialsDto>(
      {
        path: `${EndpointPaths.VENDOR_CUSTOMER_PREPEND_PATH}${vendorCustomerId}${EndpointPaths.VENDOR_CUSTOMER_API_USERS_APPEND_PATH}`,
        body: createVendorApiUserDto.toDto(),
      },
    );
  }

  /**
   * Update vendor customer
   *
   * @public
   * @async
   */
  public async updateCustomer(
    vendorCustomerId: string,
    updateVendorCustomerDto: UpdateVendorCustomerDto,
  ): Promise<AnybillResult<VendorCustomerDto>> {
    return BaseService.Instance.put<VendorCustomerDto>(
      {
        path: `${EndpointPaths.VENDOR_CUSTOMER_PREPEND_PATH}${vendorCustomerId}`,
        body: updateVendorCustomerDto.toDto(),
      },
    );
  }

  /**
   * Update a store
   *
   * @public
   * @async
   */
  public async updateStore(
    vendorCustomerId: string,
    storeId: string,
    createStoreDetailDto: CreateStoreDetailDto,
  ): Promise<AnybillResult<StoreDto>> {
    return BaseService.Instance.put<StoreDto>(
      {
        path: `${EndpointPaths.VENDOR_CUSTOMER_PREPEND_PATH}${vendorCustomerId}${EndpointPaths.VENDOR_CUSTOMER_STORES_APPEND_PATH}/${storeId}`,
        body: createStoreDetailDto.toDto(),
      },
    );
  }

  /**
   * Update a vendor api user
   *
   * @public
   * @async
   */
  public async updateVendorAPIUser(
    vendorCustomerId: string,
    vendorApiUserId: string,
    updateVendorApiUserDto: UpdateVendorApiUserDto,
  ): Promise<AnybillResult<undefined>> {
    return BaseService.Instance.put<undefined>(
      {
        path: `${EndpointPaths.VENDOR_CUSTOMER_PREPEND_PATH}${vendorCustomerId}${EndpointPaths.VENDOR_CUSTOMER_API_USERS_APPEND_PATH}/${vendorApiUserId}`,
        body: updateVendorApiUserDto.toDto(),
      },
    );
  }

  /**
   * Remove a store from customer
   */
  public async deleteStore(
    vendorCustomerId: string,
    storeId: string,
  ): Promise<AnybillResult<undefined>> {
    return BaseService.Instance.delete<undefined>(
      {
        path: `${EndpointPaths.VENDOR_CUSTOMER_PREPEND_PATH}${vendorCustomerId}${EndpointPaths.VENDOR_CUSTOMER_STORES_APPEND_PATH}/${storeId}`,
      },
    );
  }

  /**
   * Remove a vendor api user
   *
   * @public
   * @async
   */
  public async deleteVendorApiUser(
    vendorCustomerId: string,
    vendorApiUserId: string,
  ): Promise<AnybillResult<undefined>> {
    return BaseService.Instance.delete<undefined>(
      {
        path: `${EndpointPaths.VENDOR_CUSTOMER_PREPEND_PATH}${vendorCustomerId}${EndpointPaths.VENDOR_CUSTOMER_API_USERS_APPEND_PATH}/${vendorApiUserId}`,
      },
    );
  }

  /**
   * Remove a customer
   */
  public async deleteCustomer(
    vendorCustomerId: string,
  ): Promise<AnybillResult<VendorCustomerDto>> {
    return BaseService.Instance.delete<VendorCustomerDto>(
      {
        path: `${EndpointPaths.VENDOR_CUSTOMER_PREPEND_PATH}${vendorCustomerId}`,
      },
    );
  }

  /**
   * Remove a store icon
   *
   * @public
   * @async
   */
  public async deleteStoreIcon(
    vendorCustomerId: string,
    storeId: string,
  ): Promise<AnybillResult<undefined>> {
    return BaseService.Instance.delete<undefined>(
      {
        path: `${EndpointPaths.VENDOR_CUSTOMER_PREPEND_PATH}${vendorCustomerId}${EndpointPaths.VENDOR_CUSTOMER_STORES_APPEND_PATH}/${storeId}${EndpointPaths.VENDOR_CUSTOMER_ICON_APPEND_PATH}`,
      },
    );
  }
}
