import { defineStore } from "pinia";
import type ActiveDirectoryTokenDto from "~/models/ActiveDirectoryTokenDto";

export const useAuthModule = defineStore(
  "auth",
  () => {
    const authenticated = ref(false);
    const loggedInUser = ref("");
    const token = ref<ActiveDirectoryTokenDto | undefined>();

    function setToken(tokenResponse: ActiveDirectoryTokenDto, loginUsername?: string) {
      if (loginUsername)
        loggedInUser.value = loginUsername;

      authenticated.value = true;
      token.value = { ...tokenResponse } as ActiveDirectoryTokenDto;
    }

    function logout() {
      reset();
      reloadNuxtApp({
        path: "/login",
      });
    }

    function reset() {
      token.value = undefined;
      authenticated.value = false;
      loggedInUser.value = "";
    }

    // TODO: refresh token mechanic is broken in backend
    // async function refreshToken(): Promise<boolean> {
    //   if (token.value?.refresh_token) {
    //     const refreshTokenResponse = await AuthProvider.Instance.refreshToken(token.value.refresh_token);

    //     if (refreshTokenResponse.success) {
    //       loginWithToken(refreshTokenResponse.value);
    //       router.push("/dashboard");
    //       return true;
    //     }
    //     else {
    //       logout();
    //       router.push("/login");
    //       return false;
    //     }
    //   }
    //   return false;
    // }

    return { login: setToken, logout, reset, loggedInUser, authenticated, token };
  },
  {
    persist: true,
  },
);
