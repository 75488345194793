import { defineStore } from "pinia";
import type CreatePortalUserDto from "~/models/CreatePortalUserDto";
import AccessGroupCustomerEnum from "~/models/AccessGroupCustomerEnum";
import PortalUserPermissionDto from "~/models/PortalUserPermissionDto";
import PermissionEnum from "~/models/PermissionEnum";
import type PortalUserDto from "~/models/PortalUserDto";
import type { AnybillResult } from "~/additionalModels/AnybillResponse";

export const usePortalUsersModule = defineStore("portalUsers", () => {
  const userModule = useUserModule();
  const { userRights } = storeToRefs(userModule);

  const loading = ref(false);
  const users = ref<PortalUserDto[] | null>(null);
  const initialized = computed(() => !!users.value);

  /* INTERNAL FUNCTIONS */
  function _setUsers(pUsers: PortalUserDto[] | null) {
    users.value = pUsers;
  }

  function _addUser(user: PortalUserDto) {
    users.value!.push(user);
  }

  function _removeUser(id: string) {
    users.value = users.value!.filter(c => c.id !== id);
  }

  function _updateUser(u: PortalUserDto) {
    const index = users.value!.findIndex(user => user.id === u.id);
    if (index !== -1)
      users.value![index] = u;
  }

  function _setLoading(newState: boolean) {
    loading.value = newState;
  }

  /* PUBLIC FUNCTIONS */
  async function reset() {
    _setUsers(null);
  }

  async function populate() {
    _setLoading(true);
    if (userRights.value?.get(AccessGroupCustomerEnum.PortalUser) ?? 0 >= 1) {
      const response = await useTypedFetch<AnybillResult<PortalUserDto[]>>("/userPortaluserService/get?skip=0&take=100");
      if (response.success) {
        _setUsers(response.value);
      }
      else {
        await AnybillLogger.instance.error("PortalUserModule populate userPortaluserService/get", new Error(response.errorMessage ?? "no details"));
        return;
      }
    }
    else { _setUsers([]); }

    _setLoading(false);
  }

  async function addUser(user: CreatePortalUserDto): Promise<PortalUserDto | null> {
    _setLoading(true);
    for (const p of user.permissions!) {
      Object.assign(p, new PortalUserPermissionDto({
        accessGroup: Object.keys(AccessGroupCustomerEnum).indexOf(AccessGroupCustomerEnum[p.accessGroup ?? "None"]) as any,
        permission: Object.keys(PermissionEnum).indexOf(p.permission) as any,
      }));
    }

    const response = await useTypedFetch<AnybillResult<PortalUserDto>>("/userPortaluserService/post", {
      body: {
        user,
      },
    });
    if (response.success) {
      _addUser(response.value);
    }
    else {
      await AnybillLogger.instance.error("PortalUserModule add User userPortaluserService/post", new Error(response.errorMessage ?? "no details"));
      return null;
    }
    _setLoading(false);

    return response.value;
  }

  async function updateUserPermissions(user: PortalUserDto): Promise<void> {
    _setLoading(true);
    for (const p of user.permissions!) {
      Object.assign(p, new PortalUserPermissionDto({
        accessGroup: Object.keys(AccessGroupCustomerEnum).indexOf(AccessGroupCustomerEnum[p.accessGroup ?? "None"]) as any,
        permission: Object.keys(PermissionEnum).indexOf(p.permission) as any,
      }));
    }
    user.permissions = user.permissions?.map(p => new PortalUserPermissionDto(p)) ?? [];
    const response = await useTypedFetch<AnybillResult<PortalUserDto>>("/userPortaluserService/put", {
      user,

    });
    if (response.success) {
      _updateUser(user);
    }
    else {
      await AnybillLogger.instance.error("PortalUserModule update userpermissions userPortaluserService/put", new Error(response.errorMessage ?? "no details"));
      return;
    }

    _setLoading(false);
  }

  async function deleteUser(user: PortalUserDto) {
    _setLoading(true);

    const response = await useTypedFetch<AnybillResult<PortalUserDto>>("/userPortaluserSpecificService/delete", {
      portalUserId: user.id,
    });

    if (response.success) {
      _removeUser(user.id);
    }
    else {
      await AnybillLogger.instance.error("PortalUserModule delete userPortaluserSpecificService/delete", new Error(response.errorMessage ?? "no details"));
      return;
    }

    _setLoading(false);
  }

  return { initialized, loading, reset, populate, users, addUser, updateUserPermissions, deleteUser };
});
