import type { AnybillResult } from "~/additionalModels/AnybillResponse";
import EndpointPaths from "~/config/constants/EndpointPaths";
import type ActiveDirectoryTokenDto from "~/models/ActiveDirectoryTokenDto";
import type LoginUserDto from "~/models/LoginUserDto";
import type UserInfoDto from "~/models/UserInfoDto";
import BaseService from "~/services/BaseService";

export class AuthProvider {
  private static _instance: AuthProvider;

  static get Instance(): AuthProvider {
    if (AuthProvider._instance === undefined)
      AuthProvider._instance = new AuthProvider();

    return AuthProvider._instance;
  }

  public async login(loginUserDto: LoginUserDto): Promise<AnybillResult<ActiveDirectoryTokenDto>> {
    const loginResponse = await BaseService.Instance.post<ActiveDirectoryTokenDto>({
      path: EndpointPaths.LOGIN_PATH,
      body: JSON.stringify(loginUserDto),
      authNeeded: false,
    });
    if (loginResponse.success) {
      useAuthModule().login(loginResponse.value, loginUserDto.username ?? undefined);
      await this.setUser();
    }
    return loginResponse;
  }

  public async refreshToken(refreshToken: string): Promise<AnybillResult<ActiveDirectoryTokenDto>> {
    return BaseService.Instance.post<ActiveDirectoryTokenDto>({
      path: EndpointPaths.REFRESH_TOKEN_PATH,
      body: refreshToken,
      authNeeded: false,
    });
  }

  public async setUser(): Promise<AnybillResult<UserInfoDto>> {
    const userInfoResponse = await BaseService.Instance.get<UserInfoDto>({
      path: EndpointPaths.GET_USER_INFO_PATH,
    });

    if (userInfoResponse.success) {
      const isVend = userInfoResponse.value.type === "vendorCustomer";
      const isDist = userInfoResponse.value.type === "distributorCustomer";
      await dispatchModuleLoad(isVend, isDist, userInfoResponse.value);
    }

    return userInfoResponse;
  }
}
