import { defineStore } from "pinia";
import { SignalDispatcher } from "ste-signals";
import type { AnybillResult } from "~/additionalModels/AnybillResponse";
import type GetmyMarketingEmail from "~/models/GetmyMarketingEmailDto";

export const useMarketingMailsModule = defineStore("marketingMails", () => {
  const mails = ref<GetmyMarketingEmail[]>([]);
  const loading = ref(false);
  const totalMailCount = ref(0);
  const _onPopulated: SignalDispatcher = new SignalDispatcher();

  const initialized = computed(() => !!mails.value.length);

  const onPopulated = computed(() => _onPopulated.asEvent());

  async function populate({ skip = 0, preserve = false }: { skip: number; preserve: boolean } = {
    skip: 0,
    preserve: false,
  }) {
    loading.value = true;
    const mailsResponse = await useTypedFetch<AnybillResult<GetmyMarketingEmail[]>>(`/marketingmailService/get?skip=${skip}`);
    if (mailsResponse.success) {
      if (mailsResponse.statusCode !== 204) {
        if (preserve)
          mails.value = mailsResponse.value;
        else
          mails.value = (mails.value ?? []).concat(mailsResponse.value);
      }
    }
    else {
      AnybillLogger.instance.error(`${mailsResponse.statusCode} MarketingMailsModule populate - mails`);
      return;
    }

    totalMailCount.value = mails.value.length;
    loading.value = false;
  }

  async function deleteMail(removedMail: GetmyMarketingEmail) {
    mails.value = (mails.value ?? []).filter((mail) => {
      return removedMail.email !== mail.email;
    });
    const deleteMailResponse = await useTypedFetch<AnybillResult<any>>(`/marketingmailService/delete?mail=${removedMail.email}`);
    if (!deleteMailResponse.success)
      AnybillLogger.instance.error(`${deleteMailResponse.statusCode} MarketingMailsModule delete - mail`);
  }

  return {
    mails,
    loading,
    initialized,
    onPopulated,
    populate,
    deleteMail,
    totalMailCount,
  };
});
