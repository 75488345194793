import InvoicingCycleEnum from "~/models/InvoicingCycleEnum";
import CustomerSegmentEnum from "~/models/CustomerSegmentEnum";
import BillingTypeEnum from "~/models/BillingTypeEnum";
import ContactPersonDto from "~/models/ContactPersonDto";
import type InstalledAppDto from "~/models/InstalledAppDto";
import VendorCustomerDistributorDto from "~/models/VendorCustomerDistributorDto";
import VendorCustomerPosDto from "~/models/VendorCustomerPosDto";
import VendorCustomerDto from "~/models/VendorCustomerDto";
import VendorDto from "~/models/VendorDto";
import UserInfoDto from "~/models/UserInfoDto";

export default class VendorCustomerUserInfoDto extends UserInfoDto {
  vendor: VendorDto = new VendorDto();
  vendorCustomer: VendorCustomerDto = new VendorCustomerDto();
  linkedPosCustomer: VendorCustomerPosDto = new VendorCustomerPosDto();
  linkedDistributorCustomer: VendorCustomerDistributorDto = new VendorCustomerDistributorDto();
  /**
   * @description List of installed apps
   */
  installedApps: Array<InstalledAppDto> | null = null;
  /**
   * @description Flag if a vendor customer is allowed to pay an invoice by bank transfer.
   */
  isBankTransferAllowed: boolean = false;
  contactPerson: ContactPersonDto = new ContactPersonDto();
  /**
   * @description Customers homepage
   */
  homepage: string | null = null;
  billingType: BillingTypeEnum = BillingTypeEnum.VendorBilling;
  segment: CustomerSegmentEnum = CustomerSegmentEnum.C;
  vatId: string | null = null;
  taxNumber: string = "";
  packageId: string | null = null;
  invoicingCycle: InvoicingCycleEnum = InvoicingCycleEnum.Monthly;
  firstName: string = "";
  lastName: string = "";
  bookedPackageAt: string = "";
  invoiceEmail: string = "";

  constructor(
        vendorCustomerUserInfoDtoPart: Partial<VendorCustomerUserInfoDto> = {},
  ) {
    super();
    Object.assign(this, vendorCustomerUserInfoDtoPart);
  }

  toJSON() {
    return Object.assign(super.toJSON(), {
      vendor: this.vendor,
      vendorCustomer: this.vendorCustomer,
      linkedPosCustomer: this.linkedPosCustomer,
      linkedDistributorCustomer: this.linkedDistributorCustomer,
      installedApps: this.installedApps,
      isBankTransferAllowed: this.isBankTransferAllowed,
      contactPerson: this.contactPerson,
      homepage: this.homepage,
      billingType: this.billingType,
      segment: this.segment,
      packageId: this.packageId,
      invoicingCycle: this.invoicingCycle,
      firstName: this.firstName,
      lastName: this.lastName,
      bookedPackageAt: this.bookedPackageAt,
      invoiceEmail: this.invoiceEmail,
    });
  }

  static testValuesFactoryAllNull() {
    return new VendorCustomerUserInfoDto({
      vendor: new VendorDto(),
      vendorCustomer: new VendorCustomerDto(),
      linkedPosCustomer: new VendorCustomerPosDto(),
      linkedDistributorCustomer: new VendorCustomerDistributorDto(),
      installedApps: null,
      isBankTransferAllowed: false,
      contactPerson: new ContactPersonDto(),
      homepage: null,
      billingType: BillingTypeEnum[Object.values(BillingTypeEnum)[0]],
      segment: CustomerSegmentEnum[Object.values(CustomerSegmentEnum)[0]],
      packageId: null,
      invoicingCycle:
                InvoicingCycleEnum[Object.values(InvoicingCycleEnum)[0]],
    });
  }

  static testValuesFactorySomeNull() {
    return new VendorCustomerUserInfoDto({
      vendor: new VendorDto(),
      vendorCustomer: new VendorCustomerDto(),
      linkedPosCustomer: new VendorCustomerPosDto(),
      linkedDistributorCustomer: new VendorCustomerDistributorDto(),
      installedApps: null,
      isBankTransferAllowed: false,
      contactPerson: new ContactPersonDto(),
      homepage: null,
      billingType: BillingTypeEnum[Object.values(BillingTypeEnum)[0]],
      segment: CustomerSegmentEnum[Object.values(CustomerSegmentEnum)[0]],
      packageId: "VJFvVOvhWpkyNdcxdzlUZoPORJenkXYA",
      invoicingCycle:
                InvoicingCycleEnum[Object.values(InvoicingCycleEnum)[0]],
    });
  }

  static testValuesFactoryNoneNull() {
    return new VendorCustomerUserInfoDto({
      vendor: new VendorDto(),
      vendorCustomer: new VendorCustomerDto(),
      linkedPosCustomer: new VendorCustomerPosDto(),
      linkedDistributorCustomer: new VendorCustomerDistributorDto(),
      installedApps: [],
      isBankTransferAllowed: false,
      contactPerson: new ContactPersonDto(),
      homepage: "HOEASbBgssNBNZBgFOrBzcARtufDYwRG",
      billingType: BillingTypeEnum[Object.values(BillingTypeEnum)[0]],
      segment: CustomerSegmentEnum[Object.values(CustomerSegmentEnum)[0]],
      packageId: "OavsXoRZzwxlFyQgDUEsDUnridbtZANV",
      invoicingCycle:
                InvoicingCycleEnum[Object.values(InvoicingCycleEnum)[0]],
    });
  }
}
