import { startOfWeek } from "date-fns";
import { getISOWeek } from "date-fns/getISOWeek";
import { acceptHMRUpdate, defineStore } from "pinia";
import type { AnybillResult } from "~/additionalModels/AnybillResponse";
import { OverallBillsPlotMode } from "~/models/enum/OverallBillsPlotMode";

/**
 *  @deprecated Use useDataAnalyticsModule() instead
 */
export const useStatisticsModule = defineStore("statistics", () => {
  const userModule = useUserModule();
  const {
    isVendorCustomer,
    isPOSCustomer: isUserPOS,
    isDistributorCustomer: isUserDistributor,
  } = storeToRefs(userModule);
  // ** STATES **
  // #region states
  const loading = ref<boolean>(false);
  const failed = ref<boolean>(false);
  // #endregion
  // ** DATA **
  // #region data
  const billsOverallTotal = ref<number>(0);
  const billsLastSevenDays = ref<Map<string, number> | null>(null); // includes today!
  const billsPerWeek = ref<Map<string, number> | null>(null);
  const billsPerMonth = ref<Map<number, number> | null>(null);
  const billsPerYear = ref<Map<number, number> | null>(null);
  const customerIDs = ref<string[] | null>(null);
  // #endregion
  // ** UI/VIEW **
  // #region ui-view
  const viewCurrentPlotMode = ref<OverallBillsPlotMode>(OverallBillsPlotMode.ThisWeek);
  // TODO is this store Id or vendor customer? (or variable???)
  const viewSelectedIds = ref<string[]>([]);
  const viewSelectedDateRange = ref<Date>();
  const viewSelectedVendorCustomerIds = ref<string[]>([]);
  // #endregion
  // ** INTERNAL FUNCTIONS **
  // #region internal-func

  async function _getOverallBills(_params?: {
    vendorCustomerIds?: string[];
    storeIds?: string[];
  }) {
    const result = await useTypedFetch<AnybillResult<any>>("/statisticsBillcountService/post", {
      storeIds: _params?.storeIds,
      vendorCustomerIds: _params?.vendorCustomerIds,
    });

    if (result.success) {
      const val = Number(result.value);
      return Number.isNaN(val) ? 0 : val;
    }

    AnybillLogger.instance.warn("[Statistics][Overall]: Call failed", new Error(result.errorMessage ?? "no message"));
    return 0;
  }

  async function _getWeeklyBills(year: number, week: number, _params?: {
    vendorCustomerIds?: string[];
    storeIds?: string[];
  }) {
    const result = await useTypedFetch<AnybillResult<Map<string, number>>>(
      `/statisticsBillcountPerweekService/post`,
      {
        year,
        week,
        storeIds: _params?.storeIds,
        vendorCustomerIds: _params?.vendorCustomerIds,
      },
    );

    if (result.success)
      return result.value;

    AnybillLogger.instance.warn("[Statistics][Weekly]: Call failed", new Error(result.errorMessage ?? "no message"));
    return new Map();
  }

  async function _getMonthlyBills(year: number, month: number, _params?: {
    vendorCustomerIds?: string[];
    storeIds?: string[];
  }) {
    const result = await useTypedFetch<AnybillResult<Map<number, number>>>(
      `/statisticsBillcountPermonthService/post`,
      {
        year,
        month,
        storeIds: _params?.storeIds,
        vendorCustomerIds: _params?.vendorCustomerIds,
      },
    );

    if (result.success) {
      const what = result.value;
      return result.value;
    }
    AnybillLogger.instance.warn("[Statistics][Monthly]: Call failed", new Error(result.errorMessage ?? "no message"));
    return new Map();
  }

  async function _getYearlyBills(year: number, _params?: {
    vendorCustomerIds?: string[];
    storeIds?: string[];
  }) {
    const result = await useTypedFetch<AnybillResult<Map<number, number>>>(
      `/statisticsBillcountPeryearService/post`,
      {
        year,
        storeIds: _params?.storeIds,
        vendorCustomerIds: _params?.vendorCustomerIds,
      },
    );

    if (result.success)
      return result.value;

    AnybillLogger.instance.warn("[Statistics][Yearly]: Call failed", new Error(result.errorMessage ?? "no message"));
    return new Map();
  }

  function _getLastSevenDays(dataMonth: Map<number, number>) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const last7DaysData = new Map<string, number>();

    // Loop through the last 7 days (including today)
    for (let i = 0; i < 7; i++) {
      // Calculate the date of the current day
      const date = new Date(today);
      date.setDate(today.getDate() - i); // Subtract i days from today to get the date of the current day

      // Get the day number of the current day (1 to 31)
      const dayNumber = date.getDate();

      // const dax = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 23, 2, 503, 323, 124, 549, 32];
      const dax = Object.values(dataMonth);
      const dataForDay = dax[dayNumber] ?? 0;

      last7DaysData.set(dayNumber.toString(), dataForDay);
    }

    // last7DaysData now contains the data for the last 7 days, including today
    return last7DaysData;
  }
  // #endregion
  // ** PUBLIC FUNCTIONS **
  // #region public-func

  async function populate() {
    failed.value = false;
    loading.value = true;

    if (isUserPOS.value || isUserDistributor.value) {
      // TODO: DO this condition for POS
      // if (!VendorCustomerModule.onPopulated.has(setCustomerIds)) {
      //     VendorCustomerModule.onPopulated.subscribe(setCustomerIds);
      //     return; // leave early cause no ids given
      // }
    }

    let _billsOverall = 0;
    let _billsPerWeek: Map<string, number> = new Map();
    let _billsPerMonth: Map<number, number> = new Map();
    let _billsPerYear: Map<number, number> = new Map();

    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    const week = getISOWeek(startOfWeek(today));
    viewSelectedDateRange.value = today;

    try {
      if (isVendorCustomer.value) {
        _billsOverall = await _getOverallBills();
        _billsPerWeek = await _getWeeklyBills(year, week);
        _billsPerMonth = await _getMonthlyBills(year, month);
        _billsPerYear = await _getYearlyBills(year);
      }

      else { // posSoftwareCustomer
        if (!customerIDs.value || (customerIDs.value?.length ?? 0) === 0)
          return; // leave populate early

        _billsOverall = await _getOverallBills({ vendorCustomerIds: customerIDs.value });
        _billsPerWeek = await _getWeeklyBills(year, week, { vendorCustomerIds: customerIDs.value });
        _billsPerMonth = await _getMonthlyBills(year, month, { vendorCustomerIds: customerIDs.value });
        _billsPerYear = await _getYearlyBills(year, { vendorCustomerIds: customerIDs.value });
      }

      billsLastSevenDays.value = _getLastSevenDays(_billsPerMonth);

      billsOverallTotal.value = _billsOverall;
      billsPerWeek.value = _billsPerWeek;
      billsPerMonth.value = _billsPerMonth;
      billsPerYear.value = _billsPerYear;

      loading.value = false;
    }
    catch (error) {
      AnybillLogger.instance.error("Something went wrong in populating the statistics", error as Error);
      loading.value = false;
      failed.value = true;
    }
  }

  async function updateOverall() {
    let overall = 0;
    if (customerIDs === null)
      overall = await _getOverallBills({ storeIds: viewSelectedIds.value });
    else
      overall = await _getOverallBills({ vendorCustomerIds: viewSelectedIds.value });

    billsOverallTotal.value = overall;
  }

  async function updateWeeks({ year, week }: { year: number; week: number }) {
    loading.value = true;
    let _billsPerWeek: Map<string, number> = new Map();
    if (customerIDs === null)
      _billsPerWeek = await _getWeeklyBills(year, week, { storeIds: viewSelectedIds.value });
    else
      _billsPerWeek = await _getWeeklyBills(year, week, { vendorCustomerIds: viewSelectedIds.value });

    billsPerWeek.value = _billsPerWeek;
    loading.value = false;
  }

  async function updateMonths({ year, month }: { year: number; month: number }) {
    loading.value = true;
    let _billsPerMonth: Map<number, number> = new Map();
    if (customerIDs === null)
      _billsPerMonth = await _getMonthlyBills(year, month, { storeIds: viewSelectedIds.value });
    else
      _billsPerMonth = await _getMonthlyBills(year, month, { vendorCustomerIds: viewSelectedIds.value });

    billsPerMonth.value = _billsPerMonth;
    loading.value = false;
  }

  async function updateYear({ year }: { year: number }) {
    loading.value = true;
    let _billsPerYear: Map<number, number> = new Map();
    if (customerIDs === null)
      _billsPerYear = await _getYearlyBills(year, { storeIds: viewSelectedIds.value });
    else
      _billsPerYear = await _getYearlyBills(year, { vendorCustomerIds: viewSelectedIds.value });

    billsPerYear.value = _billsPerYear;
    loading.value = false;
  }

  // TODO
  function isInitialized() {
    return !!billsPerWeek.value
      && !!billsPerMonth.value && !!billsPerYear.value;
  }

  // #endregion
  return {
    populate,
    isInitialized,
    updateMonths,
    updateOverall,
    updateWeeks,
    updateYear,
    loading,
    failed,
    billsOverallTotal,
    billsLastSevenDays,
    billsPerMonth,
    billsPerWeek,
    billsPerYear,
    viewSelectedStoreIds: viewSelectedIds,
    viewCurrentPlotMode,
    viewSelectedDateRange,
  };
});

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useStatisticsModule, import.meta.hot));
